import { useState , useEffect} from 'react';
import { Nodes, demandNodes } from './Nodes'

const strat_list = {"importLowexportHigh":"Import Low Export High", 'Restricted':"Restrict Grid Connection" }

export const Overview = ({ updateOptionsFn, strategy, datetime}) => {

    const [strat, setStrat] = useState("importLowexportHigh");
    const [vppHours, setVppHours] = useState(48);
    //const [sliderDate, setSliderDate] = useState(roundMinutes(new Date())); //.toLocaleTimeString('en', {hour: "2-digit", minute:"2-digit"}))
    const [chooseDate, setChooseDate] = useState(roundMinutes(new Date()).toISOString().slice(0,16))//('2022-05-06T12:00')

    const [displayOverview, setDisplayOverview] = useState(false);

    var dateNow = new Date();
    dateNow.setTime(dateNow.getTime())
    const [timeToMidnight, setTimeToMidnight] = useState(24 - (dateNow.getHours()))

    // const [importSavings, setImportSavings] = useState(3097)
    // const [co2Savings, setCO2Savings] = useState(2683)
    // const [costSavings, setCostSavings] = useState(356)
    // const [building, setBuilding] = useState('Total_Demand')

    function roundMinutes(date) {
        if (date.toString().includes("British Summer Time")) {
            date.setHours(date.getHours() + 1 );
        } else {
            date.setHours(date.getHours())
        }
        date.setMinutes(0, 0, 0); 
        return date;
    } 
    

    const update = (sstrat,svppHours, refresh) => {
        var datetime2 =  new Date();
        datetime2.setHours(datetime.getHours()+ (svppHours-48)); 
        setStrat(sstrat);
        setVppHours(svppHours);
        if (refresh) {
             refreshVpp(datetime2);
        }
    }

    const refreshVpp = (date) => {
        var d = new Date(date)
        console.log("RefreshVpp")
        updateOptionsFn(strat, d)
    }

    const updateTime = (hour, day) => {
        var date = roundMinutes(new Date());
        var d = new Date(chooseDate)
        date.setTime(d.getTime() + (hour * 60 * 60 * 1000) + (day * 24 * 60 * 60 * 1000));
                
        if (date.toString().includes("British Summer Time")) {
            date.setHours(date.getHours() + 1)
        } 
        const futureDate = roundMinutes(new Date());
        const checkDate = new Date(date.toISOString().slice(0,16))
        if (checkDate.getTime() < futureDate.setTime(futureDate.getTime() + (24 * 60 * 60 * 1000)  + ((timeToMidnight-1) * 60 * 60 * 1000))) {
            setChooseDate(date.toISOString().slice(0,16));   
            date.setHours(date.getHours() - 1)
            refreshVpp(date);     
        }
    }


    const dateNowPlusOneDay = () => {
        var date = roundMinutes(new Date());
        date.setTime(date.getTime() + (24 * 60 * 60 * 1000) + ((timeToMidnight-1) * 60 * 60 * 1000));
        date.toISOString().slice(0,16)
        return date.toISOString().slice(0,16)
    }


    const updateDate = (h) => {
        setChooseDate(h.slice(0,16));
        refreshVpp(h.slice(0,16));
    }
       
    // const refreshStatisticState = async () => {
    //     try {
    //         //console.log("datetime", datetime.toISOString())
    //         var d = new Date(datetime)
    //         var month = d.getUTCMonth() + 1; //months from 1-12
    //         var day = d.getUTCDate();
    //         var year = d.getUTCFullYear();
    //         var h = d.getHours();
    //         var stDt = encodeURIComponent(year + "" + month.toString().padStart(2, '0') + "" + day.toString().padStart(2, '0') + " " + h.toString().padStart(2, '0') + ":00");
    //         console.log("stDt", stDt, h)
    //         let apipath = 'http://dcsdcvpp.izon.live:5000'
    //         if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //             apipath = 'http://127.0.0.1:5000'
    //         } 

    //         const response = await fetch(apipath + `/statistics?strategy=${strategy}&datetime=${stDt}&siteRow=${building}`);
    //         const data = await response.json();
    //         console.log("Statistics", data[building])
    //         const import_amount = Math.round((data[building].ImportProportion * 2.89 + Number.EPSILON) * 100) / 100
    //         setImportSavings(import_amount)
    //         const carbon_amount = Math.round((data[building].CarbonProportion + Number.EPSILON) * 100) / 100
    //         setCO2Savings(carbon_amount) 
    //         const cost_amount = Math.round((data[building].CostProportion + Number.EPSILON) * 100) / 100
    //         setCostSavings(cost_amount) 
    //     }
    //     catch (e) {
    //         console.error(e)
    //     }
    // }

    useEffect(() => {
        //refreshStatisticState();
    }, [datetime]);

    const css = "Overview " + ((displayOverview)?'showexpanded':'showcontracted');
    const cssColapse = "fa " + ((displayOverview)?'fa-chevron-down':'fa-chevron-up');
    const futureHourDate = roundMinutes(new Date());
    const futureDayDate = roundMinutes(new Date());    

    return (
    <div className={css} onClick={(e) => e.stopPropagation()}>
        <div className="flex-grid">
            <div className="stratSelect col" style={{marginTop: '10px'}}>
                <label>Strategy</label>
                <select   style={{fontSize:'120%', width: '260px',color: 'white', backgroundColor: '#3e3c45', padding: '4px'}} value={strat} onChange={(e) => update(e.target.value, vppHours, true) } id='selectConnectTo'>
                    <option value={0}>None</option>
                    {strat_list?
                    Object.keys(strat_list).map((k,i) => {
                        const b = strat_list[k];
                        return (<option value={k} key={k}>{b}</option>)
                    })
                    :''}

                </select>
            </div>
            <div className="timeSelect col">
                <label>Time</label>
                {/* <input 
                    id="typeinp" 
                    type="range" 
                    min="0" max="60" 
                    value={vppHours} 
                    step="1"
                    onChange={(e) => update(strat, e.target.value, false)}
                    onMouseUp={(e) => update(strat, e.target.value, true)}
                    onTouchEnd={(e) => update(strat, e.target.value, true)}
                    onKeyUp={(e) => update(strat, e.target.value, true)}
                    /> */}
                {/* <span>{(sliderDate)&&roundMinutes(sliderDate).toLocaleString()}</span> */}
                {/* <span>{(sliderDate)&&roundMinutes(sliderDate).toLocaleTimeString('en', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})}</span> */}
                    <div style={{ display:'inline-block',margin: '8px 0px 8px 8px', color: "white"}} className='icon-button' onClick={() => updateTime(0, -1)}>
                        <i className="fa fa-angle-double-left"/>  
                    </div>
                    <div style={{ display:'inline-block',margin: '8px 0px 8px 8px',color: 'white'}} className='icon-button'  onClick={() => updateTime(-1, 0)}>
                        <i className="fa fa-arrow-left" />
                    </div>
                    <div style={{ display:'inline-block',margin: '8px 0px 8px 8px',color: new Date(chooseDate) < (futureHourDate.setTime(futureHourDate.getTime() + ((24 * 60 * 60 * 1000) + ((timeToMidnight-2) * 60 * 60 * 1000))))? "white" : "#666" }} className='icon-button' onClick={() => updateTime(1, 0)}>
                        <i className="fa fa-arrow-right" />
                    </div>
                    <div style={{ display:'inline-block',margin: '8px 0px 8px 8px',color: new Date(chooseDate) < (futureDayDate.setTime(futureDayDate.getTime() + (((24 -1) * 60 * 60 * 1000)))) ? "white" : "#666" }} className='icon-button' onClick={() => updateTime(0, 1)}>
                        <i className="fa fa-angle-double-right"/>  
                    </div>
                <input style={{ display:'inline-block',margin: '8px 0px 8px 8px'}} type="datetime-local" id="datePicker" className="datePicker" value={chooseDate} max={dateNowPlusOneDay()}
                onChange={(e) => updateDate(e.target.value)}
                //onMouseUp={(e) => updateDate(strat, e.target.value, true)}
                //onTouchEnd={(e) => updateDate(strat, e.target.value, true)}
                //={(e) => updateDate(strat, e.target.value, true)}
                />
                {/* value="2022-05-06T12:00" min="2018-06-12T19:30" max="2024-06-12T19:30"></input> */}

            </div>
            {/* <div className="col">
                <div style={{ display:'inline-block', float:'right',margin: '8px 16px'}} className='icon-button'  onClick={() => setDisplayOverview(!displayOverview)}>
                    <i className={cssColapse} />
                </div>
            </div> */}
        </div>

        {/* <div style={{marginTop: '10px'}}>
                <label>Building</label>
                <select  className='col' id='selectBuilding' value={building} onChange={(e) => updateBuilding(e.target.value, vppHours) } style={{fontSize:'120%', width: '260px',color: 'white', backgroundColor: '#3e3c45', padding: '4px'}}>
                        {demandNodes?
                        Object.keys(demandNodes).map((k) => {
                            // .filter(([k, ]) => k.metrics.ty === "d")
                            // {const b = {(k.metricDet.ty == "d")}
                            const b = demandNodes;
                            return (<option className={b.nodetype} value={k} key={k}>{k}</option>)
                        })
                        :''}
                </select>
        </div>

        <div className="flex-grid">
            <div className="col">
                <h1>Import Daily Savings</h1>
                <img src="assets/ev.png" style={{width:'15%'}} />
                <p>Enough to drive <span style={{ fontSize:'20px'}}>{ importSavings }</span> miles</p> 
                
            </div>
            <div className="col">
                <h1>CO2 Savings</h1>
                <img src="assets/co2.png" style={{width:'60px'}} />
                <p><span style={{ fontSize:'20px'}}>{co2Savings}</span> kWH</p>
            </div>
            <div className="col">
                <h1>Electriciy Cost</h1>
                <img src="assets/wallet.png" style={{width:'60px'}} />
                <p><span style={{ fontSize:'20px'}}>£{costSavings}</span> saving</p>
            </div>
        </div> */}
    </div>)
}
