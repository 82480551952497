

export const SummaryBox = ({ summaryTitle, image, imageWidth, unit, summaryAmount, summarySentenceStart, summarySentenceEnd }) => {


    return(
        <div className="col" style={{minHeight: '200px',  fontSize:'10px', margin: '10px', padding: '2px', color: 'black', backgroundColor: '#cdcdcd',  border: '1px solid #666', borderRadius:'5px' }}>
            <h1>{summaryTitle}</h1>
            <div style={{height:'60px', width:imageWidth, margin: '0px auto'}}><img src={image} style={{maxHeight: '50px'}} /></div>
            <p style={{ fontSize:'17px'}}>{unit}{summarySentenceStart} <span>{summaryAmount}</span> {summarySentenceEnd}</p> 
        </div>
    )
}