
export const Nodes = {
    "Generation Sites": {
        nodetype: "heading"
    },
    "Alley Theatre": {
        nodetype: "genSite",
        image:"alley.png",
        metrics: [{n:'Alley Theatre',ty:'d' },{n:'Alley Theatre Solar',ty:'g' }, {n:'Actual Alley Theatre', ty:'ad', label:'Demand Actual' }, {n:'Actual Alley Theatre Solar', ty:'ag', label:'Generation Actual' }]
    },
    "Derg Valley Leisure Centre":{
        nodetype: "genSite",
        image:"derg.png",
        metrics: [{n:'Derg Valley Leisure Centre',ty:'d' },{n:'Derg Valley Leisure Centre Solar',ty:'g' }, {n:'Actual Derg Valley Leisure Centre', ty:'ad', label:'Demand Actual' }, {n:'Actual Derg Valley Leisure Centre Solar', ty:'ag', label:'Generation Actual' }]
    },
    "Irish Street":{
        nodetype: "genSite",
        image:"irish.png",
        metrics: [{n:'Irish Street',ty:'d' },{n:'Irish Street Solar',ty:'g' }]
    },
    "Melvin Sports Hall":{
        nodetype: "genSite",
        image:"melvin.png",
        metrics: [{n:'Melvin Sports Hall Strabane',ty:'d' },{n:'Melvin Sports Hall, Strabane Solar',ty:'g' }, {n:'Actual Melvin Sports Hall Strabane', ty:'ad', label:'Demand Actual' }, {n:'Actual Melvin Sports Hall Strabane Solar', ty:'ag', label:'Generation Actual' }]
    },
    "Strahans Road": {
        nodetype: "genSite",
        metrics: [{n:'Strahans Road, Strabane, BT82 9SF',ty:'d' },{n:'Strahans Road, Strabane, BT82 9SF Solar',ty:'g' }, {n:'Actual Strahans Road, Strabane, BT82 9SF', ty:'ad', label:'Demand Actual' }, {n:'Actual Strahans Road, Strabane, BT82 9SF Solar', ty:'ag', label:'Generation Actual' }],
        image:"strahan.png"
    },
    "Demand Sites": {
        nodetype: "heading"
    },
    "Brandywell": {
        nodetype: "demandSite",
        metrics: [{n:'BRANDYWELL',ty:'d'}]
    },
    "City Baths":{
        nodetype: "demandSite",
        metrics: [{n:'CITY SWIMMING BATHS',ty:'d'}]
    },
    "Council Offices":{
        nodetype: "demandSite",
        metrics: [{n:'COUNCIL OFFICES, 47 DERRY ROAD, STRABANE',ty:'d'}]
    },
    "Foyle Arena":{
        nodetype: "demandSite",
        metrics: [{n:'Foyle Arena',ty:'d'}],
        image:'foyle.png'
    },
    "Guild Hall":{
        nodetype: "demandSite",
        metrics: [{n:'GUILDHALL',ty:'d'}]
    },
    "Pennyburn Civic Amenity Site":{
        nodetype: "demandSite",
        metrics: [{n:'Pennyburn Civic Amenity Site',ty:'d'}]
    },
    "Lincoln Court":{
        nodetype: "demandSite",
        metrics: [{n:'Lincoln Court',ty:'d'}]
    },
    "RiversDale Leasure Center":{
        nodetype: "demandSite",
        metrics: [{n:'RIVERSDALE LEISURE CENTRE Strabane',ty:'d'}]
    },
    "Strand Road": {
        nodetype: "demandSite",
        metrics: [{n:'98 STRAND ROAD',ty:'d'}]
    },
    "Templemore Sports Complex": {
        nodetype: "demandSite",
        metrics: [{n:'TEMPLEMORE SPORTS COMPLEX',ty:'d'}]
    },
    "Tower Museum": {
        nodetype: "demandSite",
        metrics: [{n:'TOWER MUSEUM',ty:'d'}]
    },
    "Top of the Hill": {
        nodetype: "demandSite",
        metrics: [{n:'Top of the Hill',ty:'d'}]
    },
    "Totals": {
        nodetype: "heading"
    },
    "Total Demand Sites": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Demand_Sites',ty:'d'}],
        image: 'town.png'
    },
    "Other Demand Sites": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Demand_Sites',ty:'d'}],
        image: 'town.png'
    },
    "Total Demand": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Demand',ty:'d'}],
        image: "pin-red.png",
        w:25
    },
    "Total Generation": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Generation',ty:'g'}],
        image: "pin-green.png",
        w:25
    },
    "Renewable": {
        nodetype: "heading"
    },
    "Total Solar": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Demand_Solar_Sites',ty:'d'},{n:'Total_Solar_Production',ty:'g'} ]
    },
    "AD Plant": {
        nodetype: "genSite",
        metrics: [{n:'Wind_Production',ty:'d'}]
    },
    "Wind Production": {
        nodetype: "genSite",
        metrics: [{n:'Wind_Production',ty:'g'}],
        image:"wind.png"
    },
    "AD Plant": {
        nodetype: "genSite",
        metrics: [{n:'Biogas_Production',ty:'g'}],
        image:"ad.png"
    },
    "Electricity Price": {
        nodetype: "genSite",
        metrics: [{n:'Wind_Production',ty:'d'}]
    },
    "Grid": {
        nodetype: "genSite",
        metrics: [{n:'Total_Energy',ty:'d'}],
        image:"grid.png"
    },
    "VPP": {
        nodetype: "heading"
    },
    "Virtual Power Plant": {
        nodetype: "vpp",
        image: "bat.png", 
        w:100,
        metrics: [{n:'Import_postVPP',ty:'d', label:'Import'}, {n:"Export_postVPP",ty:'g', label:'Export'}, {n:'BatteryCharge',ty:'b'}]
    },
    "Images": {
        nodetype: "heading"
    },
    "Interreg": {
        nodetype: "image",
        image: "Interregtran.png", 
        w:170,
        metrics: []
    },
    "Smartic": {
        nodetype: "image",
        image: "Smartic.png", 
        w:170,
        metrics: []
    },
    "DCSDC": {
        nodetype: "image",
        image: "DCSDC.png", 
        w:140,
        metrics: []
    }
}

export const demandNodes = {
    "All Buildings": {
        nodetype: "statePoint",
        metrics: [{n:'Total_Demand',ty:'d'}]
    },
    "Alley Theatre": {
        nodetype: "genSite",
        image:"alley.png",
        metrics: [{n:'Alley Theatre',ty:'d'},{n:'Alley Theatre Solar',ty:'g'}]
    },
    "Derg Valley Leisure Centre":{
        nodetype: "genSite",
        image:"derg.png",
        metrics: [{n:'Derg Valley Leisure Centre',ty:'d'},{n:'Derg Valley Leisure Centre Solar',ty:'g'}]
    },
    "Irish Street":{
        nodetype: "genSite",
        image:"irish.png",
        metrics: [{n:'Irish Street',ty:'d'},{n:'Irish Street Solar',ty:'g'}]
    },
    "Melvin Sports Hall":{
        nodetype: "genSite",
        image:"melvin.png",
        metrics: [{n:'Flood Lighting Melvin Sports',ty:'d'},{n:'Melvin Sports Hall, Strabane Solar',ty:'g'}]
    },
    "Strahans Road": {
        nodetype: "genSite",
        metrics: [{n:'Strahans Road, Strabane, BT82 9SF',ty:'d'},{n:'Strahans Road, Strabane, BT82 9SF Solar',ty:'g'}],
        image:"strahan.png"
    },
    "Brandywell": {
        nodetype: "demandSite",
        metrics: [{n:'BRANDYWELL',ty:'d'}]
    },
    "City Baths":{
        nodetype: "demandSite",
        metrics: [{n:'CITY SWIMMING BATHS',ty:'d'}]
    },
    "Council Offices":{
        nodetype: "demandSite",
        metrics: [{n:'COUNCIL OFFICES, 47 DERRY ROAD, STRABANE',ty:'d'}]
    },
    "Foyle Arena":{
        nodetype: "demandSite",
        metrics: [{n:'Foyle Arena',ty:'d'}],
        image:'foyle.png'
    },
    "Guild Hall":{
        nodetype: "demandSite",
        metrics: [{n:'GUILDHALL',ty:'d'}]
    },
    "Pennyburn Civic Amenity Site":{
        nodetype: "demandSite",
        metrics: [{n:'Pennyburn Civic Amenity Site',ty:'d'}]
    },
    "Lincoln Court":{
        nodetype: "demandSite",
        metrics: [{n:'Lincoln Court',ty:'d'}]
    },
    "RiversDale Leasure Center":{
        nodetype: "demandSite",
        metrics: [{n:'RIVERSDALE LEISURE CENTRE Strabane',ty:'d'}]
    },
    "Strand Road": {
        nodetype: "demandSite",
        metrics: [{n:'98 STRAND ROAD',ty:'d'}]
    },
    "Templemore Sports Complex": {
        nodetype: "demandSite",
        metrics: [{n:'TEMPLEMORE SPORTS COMPLEX',ty:'d'}]
    },
    "Tower Museum": {
        nodetype: "demandSite",
        metrics: [{n:'TOWER MUSEUM',ty:'d'}]
    },
    "Top of the Hill": {
        nodetype: "demandSite",
        metrics: [{n:'Top of the Hill',ty:'d'}]
    }
}

