import React,  {useState, useEffect} from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CSVLink } from "react-csv";

import {GetMetricHeading} from "./MetricLabel";
import { SummaryBox } from "./SummaryBox";


const timeframes = [
    {id:1, timeframe: "Day"}, 
    {id:2, timeframe: "Week"}, 
    {id:3, timeframe: "Month"},
    {id:4, timeframe: "Year"}];

/* PASS DATE TIME TODO */
const DetailPlot = ({ Node, strategy, datetime }) => {
    const [chartSeries, setChartSeries] = useState({})
    const [csvData, setCsvData] = useState([])

    const [importSavings, setImportSavings] = useState(3097)
    const [co2Savings, setCO2Savings] = useState(2683)
    const [costSavings, setCostSavings] = useState(356)
    const [incomeSavings, setIncomeSavings] = useState(123)
    
    const [activeTimeFrame, setActiveTimeFrame] = useState('Day');

    const updateTimeFrame = (row) => {
        setActiveTimeFrame(row.timeframe);
        //console.log("WHAT IS ACTIVE", activeTimeFrame)
    }

    var colors = ['#0072b2', '#d55e00', '#009e73', '#cc79a7', '#f0e442'];

    const allchart = (data) => {
    //console.log("allchart ", data)
    var dataSeries = []
    Node.metrics.map(s => {
        //console.log("S is ", s)
        var templist = []
        var heading = GetMetricHeading(s);
        var lStyle = s.ty;
        if ((s.ty == "ad") || (s.ty == "ag")) {
            lStyle = 'solid';
        } else {
            lStyle = 'dash';
        }
        let colour = s.ty;
        if ((s.ty == "d") || (s.ty == "ad")) {
            colour = colors[0];
        } else if (s.ty == "b") {
            colour = colors[2];
        } else {
            colour = colors[1];
        }
        
        data[s.n].map((item) => {
            templist.push(
                [Date.parse(item.Time),
                    item.Value]
            )
        })
        //console.log("templist ", templist)
        dataSeries.push({data:templist, name:heading[0], showInLegend:true, dashStyle: lStyle, color: colour})
    })
    //console.log("allchart", dataSeries)
    setChartSeries(dataSeries)
    }

    const allData = async () => {
        try {
            const colQsArr = Node.metrics.map((m,i) => { return (i>0?",":"") + encodeURIComponent(m.n) });
            const colQs = colQsArr.join('');
            //console.log("colQs", colQs)
            //console.log("datetime", datetime)

            var month = datetime.getMonth() + 1; //months from 1-12
            var day = datetime.getDate();
            var year = datetime.getUTCFullYear();
            var h = datetime.getHours();
            var stDt = encodeURIComponent(year + "" + month.toString().padStart(2, '0') + "" + day.toString().padStart(2, '0') + " " + h.toString().padStart(2, '0') + ":00");
            //var stDt = year + "-" +month + "-" +day + " " + h + ":00"  ;
            //console.log("allData stDt", stDt, h)
            let apipath = 'http://dcsdcvpp.izon.live:5000'
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                apipath = 'http://127.0.0.1:5000'
            } 
            if (Node.nodetype == 'vpp') {
                const response = await fetch(apipath + `/VPP?column=${colQs}&datetime=${stDt}&strategy=${strategy}`);
                const data = await response.json();
                allchart(data);            
            } else {
                const response = await fetch(apipath + `/data?column=${colQs}&datetime=${stDt}`);
                const data = await response.json();
                //console.log("The data is ", data);
                allchart(data);          
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    const chartOptions = {
        //colors:colors,
        chart: {
            
            backgroundColor: '#3e3c45',
            // {
            //     linearGradient: {
            //         x1: 0,
            //         y1: 0,
            //         x2: 1,
            //         y2: 1
            //     },
            //     stops: [
            //         [0, 'rgb(255, 255, 255)'],
            //         [1, 'rgb(200, 200, 255)']
            //     ]
            // },
            // Edit chart spacing
            spacingBottom: -5,
            spacingTop: 50,
            spacingLeft: 5,
            spacingRight: 50,
            // Explicitly tell the width and height of a chart
            // width: null,
            // height: null,
            //Background
            //backgroundColor: '#FFFFFF',
            //borderColor: '#000000',
            // borderWidth: 1,
            // type: 'line',
            // style: {
            //     fontFamily: 'serif'
            // }
        },
        credits: {
            enabled: false
        },

        legend: {
            // enabled: true,
      
            //layout: "vertical",
            //align: "right",
            verticalAlign: "bottom",
            floating: false,
            //x: 0, // -ve = left, +ve = right
            //y: 10, // -ve = up, +ve = down
      
            // labelFormat: "{name}",
            labelFormatter: function() {
              return this.name;
            },
            itemStyle: {
                color: 'white',
                // fontWeight: 'bold'
            }
        },

        title: {
            text: null
        },
        xAxis: {
            gridLineWidth: 0, 
            gridLineColor: '#282c34',
            type:"datetime",
            //offset: 5,
            // min: -1,
            labels: {
                style: {
                    color: 'white'
                },
                rotation:45,
                formatter: function() {
                    return Highcharts.dateFormat('%d/%m/%Y %H:%M', this.value);
                }
            },
            title: {
                // text: ' (kW/H)',
                style: {
                    color: 'white'
                }
            }
        },
        yAxis: {
            gridLineWidth: 1,
            gridLineColor: '#282c34',
            // visible: true, 
            //min: 0,
            //minPadding: 5,
            labels: {
                style: {
                    color: 'white'
                },
            },
            title: {
                text: ' (kW/H)',
                style: {
                    color: 'white'
                }
            }
        
        },

        plotOptions: {
            series: {
                //threshold:-10,
                marker: {
                    enabled: false
                }
            }
        },
    
        series: chartSeries
    }

    const refreshStatisticState = async () => {
        try {

            const nodeCols = (Node.metrics.map((m,i) => { return (i>0?",":"") + encodeURIComponent(m.n) }));
            // console.log("colQsArr1", nodeCols)
            // console.log("colQsArr2", decodeURI(nodeCols[0]))
            const colQs = nodeCols.join('');
            
            var month = datetime.getMonth() + 1; //months from 1-12
            var day = datetime.getDate();
            var year = datetime.getUTCFullYear();
            var h = datetime.getHours();
            var stDt = encodeURIComponent(year + "" + month.toString().padStart(2, '0') + "" + day.toString().padStart(2, '0') + " " + h.toString().padStart(2, '0') + ":00");
            
            let apipath = 'http://dcsdcvpp.izon.live:5000'
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                apipath = 'http://127.0.0.1:5000'
            } 

            let dataMetrics = ({})

            if (Node.nodetype == 'vpp') {
                const response = await fetch(apipath + `/statistics?strategy=${strategy}&datetime=${stDt}&siteRow=Total_Demand&timeframe=${activeTimeFrame}`);
                dataMetrics = await response.json();   
                //console.log("Statistics", dataMetrics);
                const import_amount = Math.round((dataMetrics.Total_Demand.ImportProportion * 2.89 + Number.EPSILON) * 100) / 100
                setImportSavings(import_amount)
                const carbon_amount = Math.round((dataMetrics.Total_Demand.CarbonProportion + Number.EPSILON) * 100) / 100
                setCO2Savings(carbon_amount) 
                const cost_amount = Math.round((dataMetrics.Total_Demand.CostProportion + Number.EPSILON) * 100) / 100
                setCostSavings(cost_amount) 
                const income_amount = Math.round((dataMetrics.Total_Demand.IncomeProportion + Number.EPSILON) * 100) / 100
                setIncomeSavings(income_amount) 

            } else {
                const response = await fetch(apipath + `/statistics?strategy=${strategy}&datetime=${stDt}&siteRow=${decodeURI(nodeCols[0])}&timeframe=${activeTimeFrame}`);
                dataMetrics = await response.json();  
                //console.log("dataMetrics", dataMetrics)
                const import_amount = Math.round((Object.values(dataMetrics)[0].ImportProportion * 2.89 + Number.EPSILON) * 100) / 100 //dataMetrics[decodeURI(nodeCols[0])
                setImportSavings(import_amount)  
                const carbon_amount = Math.round((Object.values(dataMetrics)[0].CarbonProportion + Number.EPSILON) * 100) / 100
                setCO2Savings(carbon_amount)   
                const cost_amount = Math.round((Object.values(dataMetrics)[0].CostProportion + Number.EPSILON) * 100) / 100
                setCostSavings(cost_amount)  
                const income_amount = Math.round((Object.values(dataMetrics)[0].IncomeProportion + Number.EPSILON) * 100) / 100
                setIncomeSavings(income_amount)    
            }       
            
        }
        catch (e) {
            console.error(e)
        }
    }

    const csvDownload = async() => {
        try {

            const colQsArr = Node.metrics.map((m,i) => { return (i>0?",":"") + encodeURIComponent(m.n) });
            const colQs = colQsArr.join('');
            //console.log("colQsArr", colQsArr[3])
            //console.log("colQs", colQs)
            // console.log("datetime", datetime)

            var month = datetime.getMonth() + 1; //months from 1-12
            var day = datetime.getDate();
            var year = datetime.getUTCFullYear();
            var h = datetime.getHours();
            var stDt = encodeURIComponent(year + "" + month.toString().padStart(2, '0') + "" + day.toString().padStart(2, '0') + " " + h.toString().padStart(2, '0') + ":00");
            //var stDt = year + "-" +month + "-" +day + " " + h + ":00"  ;
            //console.log("stDt", stDt, h)
            let apipath = 'http://dcsdcvpp.izon.live:5000'
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                apipath = 'http://127.0.0.1:5000'
            } 
            if (Node.nodetype == 'genSite') {
                //console.log("activeTimeFrame", activeTimeFrame);
                const response = await fetch(apipath + `/historicData?column=${colQs}&datetime=${stDt}&timeframe=${activeTimeFrame}`);
                var data = await response.json();
                //console.log("Historic", data)
                //console.log("Historic", Object.values(data))
                data = Object.values(data)
                const l = data[0].length;
                var parseData = [];
                for (var i = 0; i < l; i++){
                    parseData.push({Time: data[0][i].Time,
                        Consumption: data[0][i].Value,
                        Generation: data[1][i].Value,
                    })

                }
                //console.log("parseData", parseData)
                setCsvData(parseData); 
            }
        }
        catch (e) {
            console.error(e)
        }
        //console.log("csvData", csvData)
    }

    useEffect(() => {
        allData();
        refreshStatisticState();
        csvDownload();

    }, [Node, strategy, datetime, activeTimeFrame]);

    const csvName = Node.metrics[0].n + ".csv"


    return(
        
            <div onClick={(e) => e.stopPropagation()}>            
                <div className="detail-chart" style={{height: '480px', marginRight: 1, margin: '0px 16px 10px 16px', fontSize:'120%', width: '70%',color: 'white', backgroundColor: '#3e3c45', padding: '0px',  border: '2px solid black'}}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                </div>
                <div id="widget" className="flex-grid">
                    <div style={{width: '90%', fontSize:'10px', marginLeft: '9px', marginRight: '5px', paddingLeft: '0px', paddingRight: '0px', color: 'white', backgroundColor: '#cdcdcd',  border: '2px solid #666'}}>
                    {timeframes.map((timeframes) => (
                        <button key={timeframes.id} onClick={() => updateTimeFrame(timeframes)}  style={{backgroundColor: timeframes.timeframe === activeTimeFrame ? "#cdcdcd" : "#3e3c45" , fontSize:'160%', width: '25%',color: timeframes.timeframe === activeTimeFrame ? "black" : "white", padding: '5px'}}>
                        {timeframes.timeframe}
                        </button>
                    ))}
                     </div>
                     {
                     Node.metrics[0].n == "Alley Theatre" | Node.metrics[0].n == "Derg Valley Leisure Centre" | Node.metrics[0].n == "Melvin Sports Hall Strabane" | Node.metrics[0].n == "Strahans Road, Strabane, BT82 9SF"?
                    <div id="widget" className="flex-grid">
                        <CSVLink data = { csvData } filename={ csvName } style={{fontSize:'12px',textDecoration: 'none', color: 'white', backgroundColor: '#3e3c45', borderRadius: '3px', border: '2px solid #666', padding: '5px'}}>
                            <i className="fa fa-download"></i>
                        </CSVLink>
                    </div>:
                    ''
                     }
                </div>
                {/* id="widget" className="flex-direction widget" */}
                <div id="widget" className="flex-grid">
                    <SummaryBox summaryTitle = 'Import Daily Savings' image = "assets/ev.png" imageWidth = '40%' summaryAmount={importSavings} summarySentenceStart = 'Enough to drive' summarySentenceEnd = 'Miles'/>
                
                    <SummaryBox summaryTitle = 'CO2 Savings' image="assets/co2.png" imageWidth = '60px' summaryAmount={co2Savings} summarySentenceEnd = 'kWH'/>
                </div>
                <div id="widget" className="flex-grid"> 
                    <SummaryBox summaryTitle = 'Electriciy Cost' image="assets/wallet.png" imageWidth = '28%' unit='£' summaryAmount={costSavings} summarySentenceEnd = 'saving'/>

                    <SummaryBox summaryTitle = 'Income' image="assets/coins.png" imageWidth = '80px' unit='£'summaryAmount={incomeSavings}/>
                </div>
            </div>
            
    )
};
export default DetailPlot;