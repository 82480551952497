import { useDrag } from 'react-dnd';
import { ItemTypes } from './ItemTypes';

const style = {
    position: 'absolute',
    cursor: 'pointer',
    width:'200px',
    height:'150px',
    color:'black'
};
export const Box = ({ id, left, width, height, top, hideSourceOnDrag, selectbox, children, clickFn }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { id, left, top },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [id, left, top]);
    if (isDragging && hideSourceOnDrag) {
        return <div ref={drag}/>;
    }
    return (<div ref={drag} style={{ ...style, left, top,  width, height }} role="Box" onClick={(e) => {selectbox(id); e.stopPropagation(); clickFn(); }}>
			{children}
		</div>);
};
