import './App.css';
import  { DashContainer } from './DashContainer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <DndProvider backend={HTML5Backend}>
        <DashContainer />
				</DndProvider>
      </header>
    </div>
  );
}

export default App;
