import { useState, useEffect } from 'react';
import { Nodes } from './Nodes'

export const PropPanel = ({ funcAddNode, boxState, allboxes, funcSaveState, funcLoadState}) => {
    const [boxProps, setBoxProps] = useState(
        {id:0,title:'',connectTo:0,top: 0, left: 0,conColour:'red', h:150, w:200,textAlign:'center',conDir:'import', ...boxState}
    );
    const [layoutName, setLayoutName] = useState('default');

    useEffect(() => {
        console.log(boxProps);
        setBoxProps({...boxProps, ...boxState });
      }, [boxState]);

    const changeName = (title) => {
        console.log(boxProps);
        const n = Nodes[title];
        var cancel = false;
        if (n && n.nodetype == "heading") {
            cancel = true;
        }
        if (!cancel) {
            setBoxProps(
                { ...boxProps, title }
            );
        }
    }

    const changeConnectTo = (connectTo) => {
        console.log('change to:', connectTo)
        setBoxProps(
            { ...boxProps, connectTo }
        );
    }
    const changeTextAlign = (textAlign) => {
        console.log('text Align:', textAlign)
        setBoxProps(
            { ...boxProps, textAlign }
        );
    }
    

    const changeDir = (dir) => {
        console.log('changeDir', dir)
        const newState = { ...boxProps, conDir:dir }
        setBoxProps(newState);
    }

    const changeColour = (colour) => {
        console.log('changeColour:', colour)
        const newState = { ...boxProps, conColour:colour }
        setBoxProps(newState);
    }
    
    return <div onClick={(e) => { console.log('click:',e.target); e.stopPropagation(); return (false)}} className="node-props">
			<div>
                <h4 style={{textAlign:'center'}}>Properties</h4>
            </div>
            <div>

            </div>
            <div>
                <select  className='prop' value={boxProps.title} onChange={(e) => changeName(e.target.value) } id='selectNode'>
                        {Nodes?
                        Object.keys(Nodes).map((k,i) => {
                            const b = Nodes[k];
                            return (<option className={b.nodetype} value={k} key={k}>{k}</option>)
                        })
                        :''}
                </select>
            </div>
            <div>
                <label>Text Align</label>
                <select  className='prop' value={boxProps.textAlign} onChange={(e) => changeTextAlign(e.target.value) } id='selectTextAlign'>
                    <option value='center'>Center</option>
                    <option value='left'>Left</option>
                    <option value='right'>Right</option>
                    <option value='leftVert'>Left Vert</option>
                    <option value='rightVert'>Right Vert</option>
                </select>
            </div>

            <div><h2>Connector</h2></div>
            <div>
                <label>Connect To</label>
                <select  className='prop' value={boxProps.connectTo} onChange={(e) => changeConnectTo(e.target.value) } id='selectConnectTo'>
                    <option value={0}>None</option>
                    {allboxes?
                    Object.keys(allboxes).map((k,i) => {
                        const b = allboxes[k];
                        return (<option value={b.id} key={k}>{b.title}</option>)
                    })
                    :''}

                </select>
            </div>
            <div>
                <div>
                <label>Colour</label>
                </div>
                <select  className='prop' value={boxProps.conColour} onChange={(e) => changeColour(e.target.value) } id='selectColour'>
                    <option value='#da100b'>Red</option>
                    <option value='#589224'>Green</option>
                    <option value='#2d9fd4'>Blue</option>
                    <option value='#f59600'>Orange</option>
                    <option value='#626262'>Grey</option>
                    
                </select>
            </div>
            <div>
                <div>
                <label>Direction</label>
                </div>
                <select  className='prop' value={boxProps.conDir} onChange={(e) => changeDir(e.target.value) } id='selectDir'>
                    <option value='flow'>Flow</option>
                    <option value='import'>Import</option>
                    <option value='export'>Export</option>
                </select>
            </div>
            <div>
               <button stye={{marginRight:'15px'}} onClick={() => funcAddNode(boxProps.id, boxProps)}>{boxProps.id==0?'Add New':'Save'}</button>
            </div>
            <div>
               <hr />
               <input className='prop' value={layoutName} onChange={(e) => setLayoutName(e.target.value)} id='txtLabelLayout' />
               <button style={{marginRight:'15px'}} onClick={() => boxProps.title?funcSaveState(layoutName):''}>Save Layout</button>
               <button onClick={() => funcLoadState(layoutName)}>Load Layout</button>
            </div>
		</div>;
};
