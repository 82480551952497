const style = {
};
export const IsoConnector = ({ from, to, colour, angle, css }) => {
    
    const tHigh = (from[1] > to[1]);
    const ralign = (from[0] > to[0]);
    const flipHor = tHigh != ralign;
    const width = Math.abs(from[0] - to[0]);
    const height = Math.abs(from[1] - to[1]);  
    
    const A1 = [0,0];
    const B2 = [ralign?(from[0]-to[0]):(to[0]-from[0]),tHigh?(from[1]-to[1]):(to[1]-from[1])];
    const midP = [(B2[0]+A1[0])/2,(B2[1]+A1[1])/2];

    const radi = angle * 0.0174533;
    const fw = height / Math.tan(radi);

    const A2 = [A1[0]+fw, B2[1]];
    const B1 = [B2[0]-fw, A1[1]];

    // Calc of Parralel lines
    const Am = (A2[1]-A1[1]) / (A2[0]-A1[0]);
    const Ac = A1[1] - (Am*A1[0]);
    // Am and Bm Should be the same
    const Bm = (B2[1]-B1[1]) / (B2[0]-B1[0]);
    const Bc = B2[1] - (Bm*B2[0]);
    // joining line is the negitive gradient
    const Cm = (-Bm);
    const Cc = midP[1] - (Cm*midP[0]);
    // Calc Intersection points
    const Cax = (Cc - Ac) / (Am - Cm);
    const CA = [Cax, (Cm*Cax)+Cc];
    const Cbx = (Cc - Bc) / (Bm - Cm);
    const CB = [Cbx, (Cm*Cbx)+Cc];

    
    var actPath = "M " + A1[0] + " " + A1[1] + " L " + CA[0] + " " + CA[1] + " L " + CB[0] + " " + CB[1] + " L " + B2[0] + " " + B2[1];
    // Calculate offset on the X axis if the path is horizontally flipped
    const Cwidth = CA[0] - CB[0];
    const viewWidth = (Cwidth > width)?Cwidth:width;
    var xOffWidth = 0;
    var xOffsetFlip = 0;
    if (flipHor) {
        xOffsetFlip = width * -1;
    }
    // Calculate offset on the X axis Interection points increase the width of the svg
    if (Cwidth > width) {
        xOffWidth = (flipHor?-1:1)* ((Cwidth-width)/2)
    }
    // flip
    var tran = "";
    if (flipHor) {
        tran = "scale(-1,1) "
    }
    // Calculate offset on the Y axis Interection points increase the height of the svg
    const Cheight = CA[1] - CB[1];
    const viewHeight = (Cheight > height)?Cheight:height;
    var yOffset = 0;
    if (Cheight > height) {
        yOffset = (Cheight-height)/2
    }
    if ((xOffWidth + xOffsetFlip) != 0 || yOffset != 0) {
        tran = tran + "translate(" + (xOffWidth + xOffsetFlip) + "," + yOffset + ")"
    }
    if (Cwidth > width) { 
        // position the SVG left offset in opposite direction to the transform if flipped
        if (flipHor) xOffWidth = -xOffWidth
    }
    const fullCss = "energy-flow " + css
    return (
        <svg height={viewHeight} className={fullCss} width={viewWidth} style={{...style, top:(tHigh?to[1]-yOffset:from[1]-yOffset), left: (ralign?from[0]-(width+xOffWidth):from[0]-xOffWidth)}}>
            <path transform={tran} d={actPath} fill="none" stroke={colour} fillOpacity="0" strokeOpacity="1" strokeWidth={5} strokeDasharray={[10,10]} />
        </svg>
    );
};
