import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { Box } from './Box';
import update from 'immutability-helper';
import { IsoConnector } from './IsoConnector2';
import { PropPanel } from './PropPanel';
import DetailPlot from './DetailPlot';
import { Overview } from './Overview';
import {MetricLabel } from './MetricLabel';
import { Nodes } from './Nodes'


let apipath = 'http://127.0.0.1:5000'

export const DragCont = ({ hideSourceOnDrag }) => {
    const [boxes, setBoxes] = useState({});

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        apipath = 'http://127.0.0.1:5000'
    } else {
        apipath = 'http://dcsdcvpp.izon.live:5000'
    }

    //console.log(boxes[1].title)

    const newNode = { top: 0, left: 0, title: '', connectTo:0,conColour:'red', id:0, image:'blarge.png', textAlign:'center', conDir:'flow'  };
    const [currentBox, setCurrentBox] = useState(newNode)
    const [displayDetail, setDisplayDetail] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const username = urlParams.get('username');
    //console.log("username", username)
    const [isAdmin, setIsAdmin] = useState(username == 'izonAdmin');

    const [showNodeProps, setShowNodeProps] = useState(false);
    const [vppState, setVppState] = useState({});
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [strategy, setStrategy] = useState("importLowexportHigh");

    const [VPPTop, setVPPTop] = useState(379);


    useEffect(() => {
        loadState("default");
        //refreshVppState(strategy,currentDateTime);
        //updateConDir();
      }, []); // hideSourceOnDrag, currentDateTime, strategy, vppState
    
    const getMidPoint = (b) => {
        const ret = [b.left + (b.w/2), b.top + (b.h/2)];
        //console.log('mid s',ret);
        return ret;
    }
    const  getMidPointFromId = (id) => {
        if (boxes[id]) {
            const b = boxes[id]
            const ret = [b.left + (b.w/2), b.top + (b.h/2)];
            //console.log('mid t',ret);
            return ret;
        }
    }

    const addBox = useCallback((id, newbox) => {
        var numboxes = Object.keys(boxes).length;
        if (id == 0) {
            numboxes = numboxes + 1
        }
        const newid = (id == 0)?numboxes:id;
        setBoxes(update(boxes, {
                $merge: { [newid]: { ...newbox, id:newid} }
        }));
    });

    const toStandardDateTime = (datetime) => {
        var d = new Date(datetime)
        var month = d.getMonth() + 1; //months from 1-12
        var day = d.getDate();
        var year = d.getUTCFullYear();
        var h = d.getHours();
        var stDt = year + "-" +month + "-" +day + " " + h + ":00";
        return stDt;
    }

    const refreshVppState = (strategy,datetime) => {
        //console.log("strategy", strategy)
        //console.log("datetime", datetime)
        setCurrentDateTime(datetime);

        (async () => {
            var stDt = toStandardDateTime(datetime)
            // console.log("stDt", stDt)
            //stDt = "2022-01-28 11:00"
            const response = await fetch(apipath + `/vppstateat?datetime=${stDt}&strategy=${strategy}`);
            const data = await response.json();
            //console.log("inst", data)
            if (data[0]) {
            const vppstate = data[0];
            setVppState(vppstate)
            }
            })();
    };

    const saveState = (layout) => {
        const pjson = (JSON.stringify({
            layout:layout,
            data: boxes
        }
            ));
        (async () => {
            const rawResponse = await fetch(apipath + '/dashboard', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(pjson)
            });
            const content = await rawResponse.json();
          })();
    };

    const loadState = (layout) => {
        (async () => {
        const response = await fetch(apipath + `/dashboard?layout=${layout}`);
                
        const data = await response.json();
        const dash = JSON.parse(data["layout"]);
        setBoxes(dash)

        refreshVppState(strategy,currentDateTime)
        })();
    };

    const setCurrentId = useCallback((id) => {
        console.log("setting current Id", id)
        if (boxes[id]) {
            setCurrentBox({...boxes[id]});
        } else {
            setCurrentBox(newNode);
        }
        
    });

    const moveBox = useCallback((id, left, top) => {
        if (isAdmin) {
            setBoxes(update(boxes, {
                [id]: {
                        $merge: { left, top },
                    },
                }
                )
            )
        }  
    }, [boxes, setBoxes]);

    const [, drop] = useDrop(() => ({
        
        accept: ItemTypes.BOX,
        drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();
            const left = Math.round(item.left + delta.x);
            const top = Math.round(item.top + delta.y);
            moveBox(item.id, left, top);
            //console.log("boxes", boxes);
            return undefined;
        },
        
    }), [moveBox]);

    var demandSite = false;
    var genSite = false;
    var isVpp = false;
    var currentBoxMetric = '';
    var ylegend = "Demand";
    var currentNode = currentBox?Nodes[currentBox.title]:null;

    const getNode = (key) => {
        var ret = null;
        //console.log("nodes,",Nodes)
        var b = boxes[key]
        if (b) {
            if (Nodes[b.title]) {
            ret = {...b, ...Nodes[b.title]}
            //console.log("ret", ret)
            }
        }        
        return ret;
    }


    const updateConDir = (title, metrics, conDir, top) => {
        var lineCSS = 'import';

        var demand = 0;
        var generation = 0;
        if (title == 'Virtual Power Plant') {
            setVPPTop(top)
        }
        
        if (conDir == 'flow') {
            metrics.map((n,i) =>  {
               //console.log("ISO n only", n)
                //console.log("vppState", vppState)
                if (n.ty == 'd') {
                    if (typeof vppState[n.n] === 'number'){
                        demand = vppState[n.n]}
                }
                if (n.ty == 'g') {
                    if (typeof vppState[n.n] === 'number'){
                        generation = vppState[n.n]}
                    
                }
                if (demand - generation < 0){
                    //console.log('demand', demand)
                    //console.log("generation", generation)
                    lineCSS = 'export'
                    if (top > VPPTop) {
                        lineCSS = 'import'

                    }
                    if (title == "Grid") {
                        lineCSS = 'export '

                    }
                } else {
                    //console.log('demand', demand)
                    //console.log("generation", generation)
                    lineCSS = 'import'
                    if (top > VPPTop) {
                        lineCSS = 'export'

                    }
                    if (title == "Grid") {
                        lineCSS = 'import'

                    }
                }

            })
        }
        //console.log("lineCSS", lineCSS)
        // Note if the node is belew the value of the VPP node 'top' value, then the flows are negated
        // When grid demand predicted is postive, there is demand required, when negative there is excess generation.
        return lineCSS;
    }
    

    const detPanelClass = "detailPanel " + (displayDetail&&currentNode&&currentNode.id!=0?"show":"")

    return (
    <div style={styles}>
    <div ref={drop} style={stylesIn} onClick={() => setCurrentId(0)}>
        <div style={{width:'1700px',height:'800px'}}>
            {
            Object.keys(boxes).map((key,i) => {
                //console.log("boxes", boxes)
                //console.log("key", key)

                const {title, metrics, connectTo, conColour, conDir, top } = getNode(key);
            return(connectTo>0?<IsoConnector key={i} from={getMidPoint(boxes[key])} css={ updateConDir(title, metrics, conDir, top) } colour={conColour} to={getMidPointFromId(connectTo)}  radius={5} angle={30} yscale={0.5}></IsoConnector>:'')
            })
            }
           {
            Object.keys(boxes).map((key,i) => {
                const { id, left, top, title, w, h, image, conDir, conColour, metrics, textAlign, nodetype } = getNode(key);
                const textBlockAlign = "boxtext " + textAlign;
                const displayText = (nodetype != "image");
                return (<Box key={i} id={id} left={left} top={top} width={w} height={h} selectbox={setCurrentId} hideSourceOnDrag={hideSourceOnDrag} clickFn={() => nodetype === "image"? '': showNodeProps?'':setDisplayDetail(true)}>
                            <img src={'assets/' + (image?image:'blarge.png')} width="100%" />
                           {(displayText)?<div style={{position:'relative'}} >
                           <div className={textBlockAlign}>
                           <span className="nodeTitle">{title}</span>
                            {metrics.map((o,i) => (o)&&<MetricLabel key={i} vppState={vppState} metricDet={o} />)
                            }
                            </div>
                            </div>:null}
                        </Box>);
            })}

            {
            (showNodeProps)?<PropPanel funcAddNode={addBox} boxState={currentBox} allboxes={boxes} funcSaveState={saveState} funcLoadState={loadState} />
            :
            (displayDetail)?<div className={detPanelClass} onClick={(ev) => { ev.stopPropagation() }}>
                <div style={{float:'right'}} className='icon-button toprcorner'  onClick={(ev) => { setDisplayDetail(false); ev.stopPropagation() }}>
                    <i className="fa fa-times" />
                </div>
                <p style={{color: 'white'}}>{currentBox.title}</p>
                {currentNode&&<DetailPlot Node={currentNode} strategy = {strategy} datetime={currentDateTime} />}
            </div>:null
            }
            </div>
          
		</div>
        {
            isAdmin ? 
            (!showNodeProps ?
                <div className={`node-props-button icon-button toprcorner`} onClick={() => { setShowNodeProps(true); setDisplayDetail(false);  }}>
                    <i className='fa fa-cog'  />
                </div>
                :<div className={`node-props-button icon-button toprcorner`} style={{float:'right', zIndex:2 }} onClick={() => setShowNodeProps(false)} >
                    <i className='fa fa-times' />
                </div>) : null
            }
        <Overview updateOptionsFn={refreshVppState} strategy = {strategy} datetime={currentDateTime}/>
        </div>
        );
};

const styles = {
    width: '100vw',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden'
};
const stylesIn = {
    width: '100vw',
    height: 'calc(100vh - 53px)',
    position: 'relative',
    overflow: 'auto'
};
