


export const MetricLabel = ({ metricDet, vppState }) => {
    const units = metricDet.units?metricDet.units:"kWh";
    const headingStruc = GetMetricHeading(metricDet);
    const value = vppState[metricDet.n]?Math.round(vppState[metricDet.n],2):'0';
    //console.log("vppState[metricDet.n]", vppState[metricDet.n])
    var css = "val " + headingStruc[1];
    var contCss = "diag " + headingStruc[1];
    return (<span className={contCss}>
        <span className="head">{headingStruc[0]}</span>
        <span className={css}>{value}</span>
        <span className="units">{units}</span>
        </span>);
};

export const GetMetricHeading = (metricDet) => {
    let heading = metricDet.ty;
    let css = '';
    if (metricDet.ty == "d") {
        if (metricDet.label) {
            heading = metricDet.label;
            css = 'Demand';
        } else {
            heading = "Demand Predicted";
        }
    }
    else if (metricDet.ty == "g") {
        if (metricDet.label) {
            heading = metricDet.label;
            css = 'Generation';
        } else {
            heading = "Generation Predicted";
        }
    } else {
        if (metricDet.label) {
            heading = metricDet.label;
        } else {
            heading = metricDet.n;
        }
    }
    if (!css) css = heading;
    return [heading,css];
}

